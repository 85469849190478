@import url('https://fonts.cdnfonts.com/css/josefin-sans');

.pageContainer {
  display: flex;
  justify-content: center;
  background: #DFF3F5;
}

.hr {
  border-bottom: 1px solid black;
  min-width: 100%;
}